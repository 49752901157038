import { Controller } from '@hotwired/stimulus';

class NavbarController extends Controller {
  static targets = ['menu'];

  static listenOnScroll() {
    const { element } = this;

    this.menuTarget.classList.add('hidden');

    if (this.constructor.scrollOnTop) {
      element.classList.remove('shadow', 'bg-white');
    } else {
      element.classList.add('shadow', 'bg-white');
    }
  }

  static get scrollOnTop() {
    return window.scrollY < 5;
  }

  connect() {
    document.addEventListener(
      'scroll',
      NavbarController.listenOnScroll.bind(this),
    );
  }

  toggleMenu() {
    const { element, menuTarget } = this;

    menuTarget.classList.toggle('hidden');
    element.classList.add('bg-white');

    if (this.constructor.scrollOnTop) {
      element.classList.toggle('shadow');

      if (menuTarget.classList.contains('hidden')) {
        element.classList.remove('bg-white');
      }
    }
  }
}

export default NavbarController;
