import { Controller } from '@hotwired/stimulus';

class PopupController extends Controller {
  static targets = ['node'];

  connect() {
    if (!this.visible || !this.contentURL) return;

    fetch(this.contentURL)
      .then((data) => data.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }

  close() {
    this.visible = false;
    this.element.remove();
  }

  get visible() {
    return window.localStorage['subscription-popup'] !== 'false';
  }

  set visible(value) {
    this._visible = value;

    window.localStorage['subscription-popup'] = value;
  }

  get contentURL() {
    return this.data.get('content-url');
  }
}

export default PopupController;
