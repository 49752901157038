import { Controller } from '@hotwired/stimulus';

class FormController extends Controller {
  static targets = ['errors'];

  onPostError(event) {
    let [, , { response }] = event.detail;

    this.appendErrors(response)
  }

  appendErrors(errors) {
    this.errorsTarget.innerHTML = errors;
  }

  toggleVisibility({ target }) {
    document.querySelector(target.dataset.target).toggleAttribute('hidden');
  }

  addFieldToMultiple({ target }) {
    const inputGroup = target.previousElementSibling.cloneNode(true);

    inputGroup.firstChild.nextElementSibling.value = '';
    target.parentNode.insertBefore(inputGroup, target);
  }

  removeField({ target }) {
    const inputGroup = target.parentNode;
    const servicesGroup = inputGroup.parentNode;

    if (servicesGroup.querySelectorAll('input').length > 1) {
      inputGroup.remove();
    } else {
      inputGroup.firstChild.nextElementSibling.value = '';
    }
  }
}

export default FormController;
