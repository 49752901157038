import { PageRenderer } from '@hotwired/turbo';

PageRenderer.prototype.assignNewBody = function () {
  const body = document.querySelector('[data-turbo-drive-body]');
  const newElement = this.newElement.querySelector('[data-turbo-drive-body]');

  if (body && newElement) {
    body.replaceWith(newElement);
  } else if (document.body && this.newElement instanceof HTMLBodyElement) {
    document.body.replaceWith(this.newElement);
  } else {
    document.documentElement.appendChild(this.newElement);
  }
};
