import { Controller } from '@hotwired/stimulus';

class CollapseController extends Controller {
  static targets = ['body', 'icon', 'control'];

  toggle() {
    const body = this.bodyTarget;

    if (body.classList.contains('hidden')) {
      if (this.controlTarget) this.controlTarget.classList.remove('collapsed');

      this.iconTarget.classList.remove('icon-angle-down');
      this.iconTarget.classList.add('icon-angle-up');
    } else {
      if (this.controlTarget) this.controlTarget.classList.add('collapsed');

      this.iconTarget.classList.remove('icon-angle-up');
      this.iconTarget.classList.add('icon-angle-down');
    }

    body.classList.toggle('hidden');
  }
}

export default CollapseController;
